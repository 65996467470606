import Head from 'next/head';
import previewMain from '../public/preview/preview-main.png';
import { getAllArticles } from '../services/blog.service';
import { Header, Footer } from '../components';
import HomePage, { HomePageProps } from './home/home';
import staticWorks from '../utils/works';
import menu from '../utils/menu';

export default function Home({ posts, works }: HomePageProps) {
  return (
    <>
      <Head>
        <title>WE ARE EXPERTS IN WEB AND MOBILE</title>
        <meta property="og:title" content="WE ARE EXPERTS IN WEB AND MOBILE" />
        <meta property="og:image" content={`${previewMain.src}`} />
        <meta name="twitter:image" content={`${previewMain.src}`} />
        <meta property="og:image:alt" content="MOBILE AND WEB EXPERTS" />
        <meta property="og:type" content="product" />
        <meta
          property="og:description"
          content="We'll help your best ideas to turn into live projects."
        />

        <meta
          name="description"
          content="We will help your best ideas to turn into live projects. Custom software development: Web, App Development, UX/UI Design"
        />
      </Head>
      <Header appearance="transparent" appearanceLogo="primary" menu={menu} />
      <HomePage posts={posts} works={works} />
      <Footer />
    </>
  );
}

export const getServerSideProps: any = async () => {
  const works = staticWorks.slice(0, 4);

  try {
    const posts = await getAllArticles();
    return {
      props: {
        posts,
        works,
      },
    };
  } catch {
    return {
      props: {
        works,
      },
    };
  }
};
