const menu = [
  {
    id: 1,
    href: '/experience',
    label: 'Experience',
  },
  // {
  //   id: 2,
  //   label: 'Business',
  //   drop: [
  //     {
  //       id: 1,
  //       href: '/healthcare',
  //       label: 'Healthcare',
  //     },
  //     {
  //       id: 2,
  //       href: '/real-estate',
  //       label: 'Real Estate',
  //     },
  //   ],
  // },
  {
    id: 3,
    label: 'Assistance',
    drop: [
      // {
      //   id: 1,
      //   href: '/design',
      //   label: 'Design',
      // },
      {
        id: 2,
        href: '/web-development',
        label: 'Web development',
      },
      {
        id: 5,
        href: '/mobile-development',
        label: 'Mobile Development',
      },
      {
        id: 3,
        href: '/ios',
        label: 'iOS',
      },
      {
        id: 4,
        href: '/android',
        label: 'Android',
      },
    ],
  },
  {
    id: 4,
    href: '/team',
    label: 'What we do ',
  },
  {
    id: 5,
    href: '/blog',
    label: 'Our blog',
  },
];

export default menu;
