import React from 'react';
import dynamic from 'next/dynamic';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './home.module.css';
import { IArticles } from '../blog/blog.types';
import { WorksCardProps } from '../../components/works-card';
import {
  Button,
  Container,
  Htag,
  WorksCardList,
  Notification,
  PostPreviewList,
  HtagVariant,
  HtagColor,
} from '../../components';
import HomePageIntro from './home-page-intro';

const DynamicWorkCard = dynamic(() => import('../../components/works-card'), {
  ssr: true,
});

const DynamicCommentsList = dynamic(
  () => import('../../components/comments-list'),
  {
    ssr: true,
  },
);

export interface HomePageProps extends Record<string, unknown> {
  posts?: IArticles;
  works: Array<WorksCardProps>;
}

function HomePage({ posts, works }: HomePageProps) {
  return (
    <div className={styles.page}>
      <HomePageIntro />

      <section className={styles.works}>
        <Container>
          <div className={styles.works_inner}>
            <Htag
              tag="h2"
              color={HtagColor.light}
              className={styles.works_title}
              variant={HtagVariant.h2}
            >
              OUR EXPERIENCE
            </Htag>
            <div id="works">
              <WorksCardList className={styles.works_list}>
                {works.map((work, index) => (
                  <DynamicWorkCard key={index} {...work} />
                ))}
              </WorksCardList>
            </div>
          </div>
          {/* <div className={styles.works_all}>
            <Button href="/experience" appearance="primary">
              VIEW ALL EXPERIENCE
            </Button>
          </div> */}
        </Container>
      </section>

      {/* <section className={styles.tabs} id="services">
        <Container>
          <Htag
            tag="h2"
            color={HtagColor.light}
            className={styles.tabs_title}
            variant={HtagVariant.h2}
          >
            OUR METHODOLOGY
          </Htag>
          <TabsList tabs={tabs} />
        </Container>
      </section> */}

      <Container>
        <section className={styles.clients}>
          <h2 className={styles.works_title}>REVIEWS BY OUR CUSTOMERS</h2>
          <DynamicCommentsList className={styles.comments} />
        </section>
        <Notification
          title="HAVE AN IDEA FOR A PROJECT?"
          buttonName="SPEAK TO US"
          href="/contact"
          className={styles.notification}
        />
      </Container>

      {posts && (
        <section>
          <Container>
            <PostPreviewList posts={posts} />
            <div className={styles.works_all}>
              <Button href="/blog" appearance="primary">
                READ ARTICLES
              </Button>
            </div>
          </Container>
        </section>
      )}
    </div>
  );
}

export default HomePage;
