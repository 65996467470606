import {
  Container,
  ContainerFluid,
  Htag,
  HtagAlign,
  HtagVariant,
} from '../../components';
import styles from './home-page-intro.module.css';
import ClockIcon from '../../public/svg/clock.svg';
import PriceIcon from '../../public/svg/price.svg';

const list = [
  {
    title: 'Branding',
    text: '(competitors research + logo design + brandbook)',
    price: 1500,
    time: '1 week',
  },
  {
    title: 'Landing Page Design',
    text: '(4-6 blocks + contact form + mobile version)',
    price: 500,
    time: '2 days',
  },
  {
    title: 'Pitch Deck Design',
    text: '(up to 20 slides)',
    price: 1000,
    time: '4 days',
  },
  {
    title: 'Mobile App Design',
    text: '(1 simple app with iOS and Android resolution)',
    price: 1500,
    time: '1 week',
  },
  {
    title: 'Web Design',
    text: '(up to 15 pages website+ mobile responsive version)',
    price: 1500,
    time: '1 week',
  },
  {
    title: 'Wireframing',
    text: '(basic app or website + admin panel)',
    price: 2000,
    time: '2 week',
  },
];

export default function HomePageIntro() {
  return (
    <section className={styles.intro}>
      <ContainerFluid>
        <svg
          viewBox="0 0 1920 178"
          fill="none"
          style={{ width: '100%' }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 78.9713C131.606 70.0333 272.533 104.667 340.906 125.899C409.232 147.132 533.101 142.656 648.419 123.661C763.736 104.667 853.443 84.5529 994.37 133.718C1135.3 182.884 1237.83 201.878 1425.75 133.718C1613.67 65.558 1700.69 7.45464 1922 18.6177V0H0V78.9713Z"
            fill="#E8E2FF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 62.3619V58.8759C72.7375 28.8155 141.986 9.39388 266.138 33.5917C307.823 41.7179 355.507 56.6803 403.463 70.2845C450.922 83.7302 498.697 95.8856 541.284 98.4888C574.261 100.526 606.021 92.9656 640.803 84.0244C694.398 70.2392 754.804 53.1491 835.963 60.3925C898.355 65.961 973.288 89.3665 1044.79 104.646C1091.66 114.651 1136.96 121.17 1176.21 116.439C1222.58 110.848 1271.03 96.4289 1318.95 78.9993C1389.91 53.1717 1459.65 20.7345 1519.65 0H1528C1528 0.475357 1527.55 0.950714 1526.69 1.24498C1465.92 21.8889 1394.87 55.1184 1322.51 81.4439C1273.92 99.1226 1224.7 113.677 1177.65 119.359C1137.32 124.226 1090.67 117.684 1042.49 107.407C971.438 92.2186 897.001 68.881 834.971 63.3579C755.346 56.2728 696.157 73.2045 643.6 86.7181C607.645 95.9762 574.667 103.604 540.562 101.499C497.298 98.8283 448.711 86.5823 400.485 72.9102C352.755 59.3966 305.386 44.5022 263.927 36.4212C141.535 12.5403 73.7751 32.1656 3.93987 61.9997C3.39851 62.2487 2.6767 62.3619 2 62.3619Z"
            fill="white"
          />
        </svg>
        <Container>
          <Htag
            variant={HtagVariant.h1}
            align={HtagAlign.center}
            className={styles.title}
          >
            Top 6 Design Services
          </Htag>
          <ul className={styles.list}>
            {list.map((item) => (
              <li className={styles.item}>
                <div className={styles.card}>
                  <h4 className={styles.card__title}>{item.title}</h4>
                  <p className={styles.card__text}>{item.text}</p>
                  <div className={styles.card__footer}>
                    <div className={styles.card__box}>
                      <PriceIcon className={styles.card_icon} />
                      <span className={styles.card__price}>{item.price}</span>
                    </div>
                    <div className={styles.card__box}>
                      <ClockIcon className={styles.card_icon} />
                      <span className={styles.card__period}>{item.time}</span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Container>
      </ContainerFluid>
    </section>
  );
}
